import { initSelectedFunctionalTabs } from './initSelectedFunctionalTabs';
import anime from 'animejs';

export function initProgressBar() {
    // Vars
    const loadLocation = document.querySelector('[data-reverse-location="reverse-load"]');
    const resultLocation = document.querySelector('[data-reverse-location="reverse-result"]');
    const progressBar = document.querySelector('[data-reverse-progress-bar]');
    const progressBarCount = document.querySelector('[data-reverse-progress-count]');
    const icon = document.querySelector('[data-reverse-progress-icons]');
    const dataJson = document.querySelector('[data-load-user-icon]').dataset.loadUserIcon;
    const switcher = document.querySelector('[data-reverse-language]');
    const data = JSON.parse(dataJson);
    let count = 0;
    let lastProgressStep = 0;

    // Init
    switcher.classList.add('--hidden');
    initSelectedFunctionalTabs(loadLocation);
    updateProgressBar();

    // Functions
    function updateProgressBar() {
        progressBarCount.style.color = '#465173';

        anime({
            targets: progressBar,
            translateX: ['-100%', '0%'],
            easing: 'linear',
            duration: 7000,
            update: function (anim) {
                const progress = Math.round(anim.progress);
                progressBarCount.textContent = `${progress}%`;

                if (progress % 5 === 0 && progress !== lastProgressStep) {
                    lastProgressStep = progress;

                    if (count >= 5) {
                        count = 0;
                    }

                    icon.src = data[count].icon;
                    count = count + 1;
                }
            },
            complete: function () {
                progressBarCount.style.color = '#ffffff';

                setTimeout(function () {
                    initSelectedFunctionalTabs(resultLocation);
                }, 1000);
            },
        });
    }
}
