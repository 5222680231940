export function initSelectedFunctionalTabs(page) {
    const containerPages = document.querySelectorAll('[data-reverse-location]');
    const activeClass = '--show';
    const hiidenClass = '--hidden';
    const nextPage = page.dataset.reverseLocation;

    containerPages.forEach(function (container) {
        container.classList.remove(activeClass);
        container.classList.remove(hiidenClass);

        const activePage = container.dataset.reverseLocation === nextPage;

        if (activePage) {
            container.classList.add(activeClass);
        } else {
            container.classList.add(hiidenClass);
        }
    });
}
