import { initProgressBar } from './initProgressBar';

export function initStartReverseAnimation(phoneNumber) {
    const userNumbers = document.querySelectorAll('[data-reverse-load-number]');

    userNumbers.forEach((userNumber) => {
        userNumber.textContent = phoneNumber;
    });
    sendData();

    async function sendData() {
        const url = document.querySelector('[data-url-create-account]').dataset.urlCreateAccount;
        const token = document.querySelector('[data-trial-reverse-token]').value;
        const formData = new FormData();
        formData.append('token', token);
        formData.append('phoneNumber', phoneNumber);

        try {
            const response = await fetch(url, {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.status} ${response.statusText}`);
            }

            const responseData = await response;

            if (responseData.ok) {
                initProgressBar();
            }
        } catch (error) {
            console.error(error);
            throw error;
        }
    }
}
